import { createReducer, on } from "@ngrx/store";
import * as ChatActions from "./chat.actions";
import { EntityState, createEntityAdapter } from "@ngrx/entity";
import { User } from "@telespot/sdk";
import generateId from "uid";

export interface IChat {
  id: string;
  assistantId: string;
  active: boolean;
}

export interface IMessage {
  id: string;
  sender: User | IAssistant;
  timestamp: number;
  chatId: string;
  content: any;
}

export enum Context {
  GENERAL = "general",
  CASE = "case",
}

export interface IAssistant {
  readonly id: string;
  readonly name: string;
  readonly personalityPrompt: string;
  readonly description: any;
  readonly iconImage: string;
  readonly apiSpec: any;
  readonly context: any;
}

export interface ChatState {
  chats: EntityState<IChat>;
  messages: EntityState<IMessage>;
  assistants: Array<IAssistant>;
  error: string | null;
  sending: boolean;
  context: Context;
  currentUser: User;
  loading: boolean;
  activeAssistant: string;
}
export const chatAdapter = createEntityAdapter<IChat>({
  selectId: (chat) => chat.id,
});
export const messageAdapter = createEntityAdapter<IMessage>({
  selectId: (message) => message.id,
});
export const initialChatState: ChatState = {
  chats: chatAdapter.getInitialState(),
  messages: messageAdapter.getInitialState(),
  assistants: [],
  error: null,
  sending: false,
  context: null,
  currentUser: undefined,
  loading: true,
  activeAssistant: null,
};

export function getChats(state: ChatState): IChat[] {
  return Object.values(state.chats.entities ?? {});
}

export function getMessages(state: ChatState): IMessage[] {
  return Object.values(state.messages.entities ?? {});
}

export const chatReducer = createReducer(
  initialChatState,

  on(ChatActions.chatActionError, (state, { error }) => ({
    ...state,
    sending: false,
    error,
  })),
  on(ChatActions.assistantsLoaded, (state, { assistants }) => ({
    ...state,
    assistants,
    loading: false,
  })),
  on(ChatActions.loadAssistants, (state, { caseId }) => ({
    ...state,
    context: caseId ? Context.CASE : Context.GENERAL,
    loading: true,
  })),
  on(ChatActions.startChatWithAssistant, (state, { assistantId }) => ({
    ...state,
    activeAssistant: assistantId,
  })),
  on(ChatActions.setCurrentUser, (state, { user }) => ({
    ...state,
    currentUser: user,
  })),
  on(ChatActions.clearChatState, (state) => ({
    ...initialChatState,
    currentUser: state.currentUser,
  })),
  on(ChatActions.sendMessage, (state, { content, timestamp }) => {
    const chats = getChats(state);
    let activeChat = chats.find((chat) => chat.active);

    if (!activeChat) {
      activeChat = {
        id: generateId(6),
        assistantId: state.activeAssistant,
        active: true,
      };
    }

    const newMessage = {
      id: generateId(6),
      sender: state.currentUser,
      chatId: activeChat.id,
      timestamp: timestamp,
      content,
    };

    return {
      ...state,
      chats: chatAdapter.addOne(activeChat, state.chats),
      messages: messageAdapter.addOne(newMessage, state.messages),
      sending: true,
    };
  }),
  on(ChatActions.receivedMessages, (state, { messages }) => {
    const chats = getChats(state);
    const activeChat = chats.find((chat) => chat.active);

    const activeAssistant = state.assistants.find(
      (assistant) => assistant.id === state.activeAssistant
    );
    const newMessages = messages.map((m) => {
      return {
        id: generateId(6),
        sender: activeAssistant,
        chatId: activeChat.id,
        timestamp: Date.now(),
        content: m.content,
      };
    });

    return {
      ...state,
      messages: messageAdapter.addMany(newMessages, state.messages),
      sending: false,
    };
  }),
  on(ChatActions.resetChat, (state) => ({
    ...state,
    activeAssistant: null,
    messages: messageAdapter.getInitialState(),
    sending: false,
    chats: chatAdapter.getInitialState(),
  }))
);
