import { Injectable } from "@angular/core";
import { environment } from "@telespot/shared/environment";
import { Assistant, CaseType, CaseTypeAssistant, Query } from "@telespot/sdk";
import { DataService } from "@telespot/web-core";
import { defer, from } from "rxjs";
import { ChatMapper } from "./chat.mapper";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IAssistant, IMessage } from "../../state";

export enum ApiURLs {
  OPENAICHATCOMPLETION = "https://api.openai.com/v1/chat/completions",
}
@Injectable({
  providedIn: "root",
})
export class ChatService {
  constructor(private dataService: DataService, private _http: HttpClient) {}

  public fetchAssistants(caseType?: string) {
    let assistantQuery;

    if (caseType) {
      assistantQuery = new Query(CaseTypeAssistant)
        .include("assistant")
        .equalTo("caseType", CaseType.createWithoutData(caseType));
    } else {
      assistantQuery = new Query(Assistant);
    }

    return from(
      this.dataService.find(assistantQuery).then((assistants) => {
        return caseType
          ? assistants.map((a) => ({
              assistant: (a as CaseTypeAssistant).assistant,
              scheme: (a as CaseTypeAssistant).promptScheme,
            }))
          : assistants.map((a) => ({ assistant: a, scheme: "" }));
      })
    );
  }

  public async sendMessage(
    newMessage: any,
    assistant: IAssistant,
    oldMessages: IMessage[] | any[]
  ) {
    const body = ChatMapper.toAPIMessage(newMessage, assistant, oldMessages);
    const apiUrl = ApiURLs[assistant.apiSpec.api];
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${environment.llm_api.apiKey}`
    );
    try {
      return await this._http.post<any>(apiUrl, body, { headers }).toPromise();
    } catch (error) {
      console.error("API Error:", error);
      throw error;
    }
  }
}
