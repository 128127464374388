import { createAction, props } from "@ngrx/store";
import { User } from "@telespot/sdk";
import { IAssistant } from "./chat.reducer";

export interface ResponseMessage {
  sender: string;
  content: string;
}

export const chatActionError = createAction(
  "[CHAT] Action Error",
  props<{ error: string }>()
);

export const fetchAssistants = createAction(
  "[CHAT] Fetch available assistants"
);

export const setChatContext = createAction(
  "[CHAT] Set chat context",
  props<{ caseId: string }>()
);

export const loadAssistants = createAction(
  "[CHAT] Load available assistants",
  props<{ caseId: string }>()
);

export const assistantsLoaded = createAction(
  "[CHAT] Assistants loaded",
  props<{ assistants: IAssistant[] }>()
);

export const startChatWithAssistant = createAction(
  "[CHAT] Start chat with selected Assitant",
  props<{ assistantId: string }>()
);

export const sendMessage = createAction(
  "[CHAT] Send message to assistant",
  props<{ content: string; timestamp: number }>()
);

export const receivedMessages = createAction(
  "[CHAT] Receive message from assistant",
  props<{ messages: ResponseMessage[] }>()
);

export const setCurrentUser = createAction(
  "[CHAT] Set current authorized user",
  props<{ user: User }>()
);
export const resetChat = createAction("[CHAT] Reset current chat conversation");
export const clearChatState = createAction("[CHAT] Clear chat state");
