import { Object as ParseObject } from "parse";
import { CaseType } from "../case-type/case-type.model";
import { Assistant } from "../assistant/assistant.model";

export enum CaseTypeAssistantFields {
  TABLE = "CaseTypeAssistant",
  CASETYPE = "caseType",
  PROMPTSCHEME = "promptScheme",
  ASSISTANT = "assistant",
}

export class CaseTypeAssistant extends ParseObject {
  static className = CaseTypeAssistantFields.TABLE;

  constructor() {
    super(Assistant.className);
  }

  get caseType(): CaseType {
    return this.get(CaseTypeAssistantFields.CASETYPE);
  }

  get promptScheme(): string {
    return this.get(CaseTypeAssistantFields.PROMPTSCHEME || "");
  }

  get assistant(): Assistant {
    return this.get(CaseTypeAssistantFields.ASSISTANT);
  }
}
