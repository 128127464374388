import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StoreModule } from "@ngrx/store";
import { ChatEffects, chatFeatureKey, chatReducer } from "./state";
import { EffectsModule } from "@ngrx/effects";

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(chatFeatureKey, chatReducer),
    EffectsModule.forFeature([ChatEffects]),
  ],
})
export class TelespotChatDataAccessModule {}
