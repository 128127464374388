import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ChatService } from "../../services/chat/chat.service";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import * as ChatActions from "./chat.actions";
import { from, of } from "rxjs";
import {
  selectActiveAssistant,
  selectMessagesFromActiveChat,
} from "./chat.selectors";
import { ChatMapper } from "../../services/chat/chat.mapper";
import { getCurrentCase } from "@telespot/analysis-refactor/data-access";

@Injectable()
export class ChatEffects {
  setChatContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.setChatContext),
      mergeMap(({ caseId }) => [
        ChatActions.clearChatState(),
        ChatActions.loadAssistants({ caseId }),
      ]),
      catchError((error) =>
        of(
          ChatActions.chatActionError({
            error: `[setChatContext]: ${error.message}`,
          })
        )
      )
    )
  );

  loadAssistants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.loadAssistants),
      mergeMap(({ caseId }) =>
        of(caseId).pipe(
          withLatestFrom(this.store$.select(getCurrentCase(caseId)))
        )
      ),
      mergeMap(([caseId, currCase]) => {
        const caseContext = currCase?.data;
        return this.chatService
          .fetchAssistants(currCase?.caseType.objectId)
          .pipe(
            map((assistants) =>
              ChatMapper.toStateAssistant(assistants, caseContext)
            ),
            map((assistants) => ChatActions.assistantsLoaded({ assistants })),
            catchError((error) =>
              of(
                ChatActions.chatActionError({
                  error: `[loadAssistants]: ${error.message}`,
                })
              )
            )
          );
      })
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.sendMessage),
      withLatestFrom(
        this.store$.select(selectActiveAssistant),
        this.store$.select(selectMessagesFromActiveChat)
      ),
      mergeMap(([{ content, timestamp }, assistant, oldMessages]) => {
        return from(
          this.chatService.sendMessage(
            { content, timestamp },
            assistant,
            oldMessages
          )
        ).pipe(
          map((response) => {
            const mappedResponse = ChatMapper.fromAPIResponse(response);
            return ChatActions.receivedMessages({ messages: mappedResponse });
          }),
          catchError((error) => {
            return of(
              ChatActions.chatActionError({
                error: `[sendMessage]: ${error.message}`,
              })
            );
          })
        );
      })
    )
  );
  constructor(
    private actions$: Actions,
    private store$: Store,
    private chatService: ChatService
  ) {}
}
