import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageSelectorModule } from "@shared/localization";
import { TelespotChatFeatureModule } from "@telespot/chat/feature";
import { TelespotModule } from "@telespot/web-core";
import {
  AboutPopupTriggerModule,
  SupportLinkDirectiveModule,
} from "@telespot/support/feature";
import { AboutDialogComponentModule } from "@telespot/ui";

import { FooterComponent } from "./components/footer/footer.component";
import { NavBarOrganizationModule } from "./components/nav-bar-organization/nav-bar-organization.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { SideNavComponent } from "./components/side-nav/side-nav.component";

import { ChatIconComponent } from "./components/chat-icon/chat-icon.component";
import { TelespotShellComponent } from "./components/telespot-shell/telespot-shell.component";

@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    NavBarOrganizationModule,
    RouterModule,
    TranslateModule,
    LanguageSelectorModule,
    MatListModule,
    MatToolbarModule,
    MatTooltipModule,
    TelespotModule,
    MatButtonModule,
    AboutDialogComponentModule,
    AboutPopupTriggerModule,
    SupportLinkDirectiveModule,
    MatSnackBarModule,
    TelespotChatFeatureModule,
  ],
  declarations: [
    TelespotShellComponent,
    FooterComponent,
    NavBarComponent,
    SideNavComponent,
    ChatIconComponent,
  ],
  exports: [TelespotShellComponent],
})
export class TelespotShellModule {}
