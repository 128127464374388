import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { createReducer, on, Action } from "@ngrx/store";

import * as CasesActions from "./cases.actions";
import { CasesEntity } from "./cases.models";
import { Workspace } from "@telespot/sdk";
import { FilterConfig } from "@shared/ui";
import { SortDirection } from "@angular/material/sort";

export const CASES_FEATURE_KEY = "cases";

export interface caseData {
  [key: string]: any;
}
export interface caseInfo {
  name: string;
  id: string;
  data: caseData;
}

export interface AnalystDetail {
  name: string;
  entity: string;
  id: string;
}

export interface SampleAnalysts {
  sampleId: string;
  analysts: AnalystDetail[];
}
export interface State extends EntityState<CasesEntity> {
  cases: any[];
  caseList: Array<any>; // adaptaspot format only now
  currentUserId: string;
  currentCaseId: string;
  decrypted?: boolean;
  decryptedCases?: any[];
  currWorkspace: any;
  error: string | null;
  totalCases: number;
  totalWorkspaceCases: number;
  movingCases: boolean;
  copyingCase: boolean;
  deletingCase: boolean;
  copyingFindings: boolean;
  enableMosaicView: boolean;
  decryptingCases: boolean;
  compatibleWorkspaces: Workspace[];
  samplesAnalysts: SampleAnalysts[];
  filters: EntityState<FilterConfig>;
  sorts: { [key: string]: SortDirection };
  pageIndex: number;
  pageSize: number;
  loading: boolean;
  loadingCompatibleWorkspaces: boolean;
  loadingSampleAnalysts: boolean;
}

export const casesAdapter: EntityAdapter<CasesEntity> =
  createEntityAdapter<CasesEntity>();

export const filterAdapter = createEntityAdapter<FilterConfig>({
  selectId: (filter) => filter.name,
});

export const initialState: State = casesAdapter.getInitialState({
  cases: [],
  caseList: [],
  currentUserId: undefined,
  currentCaseId: undefined,
  decrypted: false,
  decryptedCases: [],
  currWorkspace: undefined,
  error: null,
  totalCases: 0,
  totalWorkspaceCases: 0,
  movingCases: false,
  copyingCase: false,
  deletingCase: false,
  copyingFindings: false,
  enableMosaicView: false,
  decryptingCases: false,
  compatibleWorkspaces: [],
  samplesAnalysts: [],
  filters: filterAdapter.getInitialState(),
  sorts: {},
  pageIndex: 0,
  pageSize: 20,
  loading: true,
  loadingCompatibleWorkspaces: false,
  loadingSampleAnalysts: false,
});

const casesReducer = createReducer(
  initialState,
  on(CasesActions.resetFiltersAndSort, (state) => ({
    ...state,
    sorts: {},
    filters: filterAdapter.getInitialState(),
  })),
  on(CasesActions.caseCopied, (state) => ({
    ...state,
    copyingCase: false,
    error: null,
  })),
  on(CasesActions.refreshCaseList, (state) => ({
    ...state,
    loading: true,
    caseList: [],
    error: null,
  })),
  on(CasesActions.decryptCases, (state) => ({
    ...state,
    decryptingCases: true,
    error: null,
  })),
  on(CasesActions.loadCases, (state) => ({
    ...state,
    decryptingCases: true,
    error: null,
  })),
  on(CasesActions.applyCaseFilter, (state, { filter: filters }) => ({
    ...state,
    filters: filterAdapter.upsertOne(filters, state.filters),
    loading: true,
    caseList: [],
    error: null,
    pageIndex: 0,
  })),
  on(CasesActions.removeCaseFilter, (state, { filter: filters }) => ({
    ...state,
    filters: filterAdapter.removeOne(filters.name, state.filters),
    loading: true,
    caseList: [],
    error: null,
  })),
  on(CasesActions.sortCases, (state, { cryteria: sorts }) => ({
    ...state,
    sorts: { ...state.sorts, ...sorts },
    loading: true,
    caseList: [],
    error: null,
  })),
  on(CasesActions.changePage, (state, { pageIndex, pageSize }) => ({
    ...state,
    pageIndex,
    pageSize,
    loading: true,
    caseList: [],
    error: null,
  })),
  on(CasesActions.listCompatibleWorkspaces, (state) => ({
    ...state,
    compatibleWorkspaces: [],
    error: null,
    loadingCompatibleWorkspaces: true,
  })),
  on(
    CasesActions.compatibleWorkspacesListed,
    (state, { compatibleWorkspaces }) => ({
      ...state,
      loadingCompatibleWorkspaces: false,
      compatibleWorkspaces,
      error: null,
    })
  ),
  on(CasesActions.moveCase, (state) => ({
    ...state,
    movingCases: true,
    error: null,
  })),
  on(CasesActions.deleteCase, (state) => ({
    ...state,
    deletingCase: true,
    error: null,
  })),
  on(CasesActions.copyCase, (state) => ({
    ...state,
    copyingCase: true,
    error: null,
  })),
  on(CasesActions.listSampleAnalysts, (state) => ({
    ...state,
    samplesAnalysts: [],
    error: null,
    loadingSampleAnalysts: true,
  })),
  on(CasesActions.sampleAnalystsListed, (state, { analysts, sampleId }) => {
    return {
      ...state,
      samplesAnalysts: state?.samplesAnalysts.concat({
        sampleId,
        analysts,
      }),
      error: null,
      loadingSampleAnalysts: true,
    };
  }),
  on(CasesActions.copyFindingsFromSample, (state) => ({
    ...state,
    copyingFindings: true,
    error: null,
  })),
  on(CasesActions.findingsFromSampleCopied, (state, { enableMosaicView }) => ({
    ...state,
    copyingFindings: false,
    enableMosaicView,
    error: null,
  })),
  on(CasesActions.casesCounted, (state, { count }) => ({
    ...state,
    totalCases: count,
  })),
  on(CasesActions.totalWorkspaceCasesCounted, (state, { count }) => ({
    ...state,
    totalWorkspaceCases: count,
  })),
  on(CasesActions.casesListed, (state, { caseList }) => ({
    ...state,
    caseList,
    loading: false,
    movingCases: false,
    deletingCase: false,
    copyingCase: false,
    error: null,
  })),
  on(CasesActions.decrypt, (state, { decrypted }) => ({
    ...state,
    decrypted: decrypted,
    error: null,
  })),
  on(CasesActions.updateCaseData, (state, { id, data, currentUserId }) => {
    let encryptedCase = state.cases.find((_case) => _case.objectId === id);
    const createdByUser = currentUserId === encryptedCase.createdBy.objectId;

    const allFields = state?.currWorkspace.caseType.fields.map((f) => f.name);

    const encryptedFields = state?.currWorkspace.caseType.fields
      .filter((f) => f.encrypted && f.type !== "case_name")
      .map((f) => f.name);

    const keysToUpdate = encryptedCase
      ? allFields.filter(
          (key) =>
            encryptedCase.data[key] !== data[key] &&
            (!createdByUser ? !encryptedFields.includes(key) : true)
        )
      : [];

    if (keysToUpdate.length > 0) {
      encryptedCase = {
        ...encryptedCase,
        name: keysToUpdate.includes("case_identifier")
          ? data["case_identifier"]
          : encryptedCase.name,
        data: {
          ...encryptedCase.data,
          ...keysToUpdate.reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {}),
        },
      };
    }

    if (createdByUser && encryptedCase.name !== data["case_identifier"]) {
      encryptedCase = {
        ...encryptedCase,
        name: data["case_identifier"],
        data: { ...encryptedCase.data, ...data["case_identifier"] },
      };
    }

    return {
      ...state,
      error: null,
      cases: [
        ...(state.cases?.filter((c) => c.objectId !== id) || []),
        encryptedCase,
      ],
    };
  }),
  on(CasesActions.updateDecryptedCaseData, (state, { id, data }) => {
    const decryptedCase = state.decryptedCases.find(
      (_case) => _case.objectId === id
    );

    const caseToUpdate = {
      ...(decryptedCase || []),
      name: data["case_identifier"]
        ? data["case_identifier"]
        : decryptedCase.name,
      data,
    };

    return {
      ...state,
      error: null,
      decryptedCases: [
        ...(state.decryptedCases?.filter(
          (c) => c.objectId !== caseToUpdate.objectId
        ) || []),
        caseToUpdate,
      ],
    };
  }),
  on(CasesActions.addDecryptedCases, (state, { decryptedCases }) => {
    const decryptedCasesToInclude = (decryptedCases || []).filter(
      (c) =>
        !state.decryptedCases.find((_case) => _case.objectId === c.objectId)
    );
    return {
      ...state,
      decryptedCases: [...state.decryptedCases, ...decryptedCasesToInclude],
      decrypted: true,
      decryptingCases: false,
      error: null,
    };
  }),
  on(
    CasesActions.casesLoaded,
    (state, { cases, currentUserId, decryptedCases }) => {
      const casesToInclude = (cases || []).filter(
        (c) => !state.cases.find((_case) => _case.objectId === c.objectId)
      );
      const decryptedCasesToInclude = (decryptedCases || []).filter(
        (c) =>
          !(state.decryptedCases || []).find(
            (_case) => _case.objectId === c.objectId
          )
      );
      return {
        ...state,
        cases: [...state.cases, ...casesToInclude],
        decryptedCases: [...state.decryptedCases, ...decryptedCasesToInclude],
        currentUserId,
        loading: false,
        decryptingCases: false,
        error: null,
      };
    }
  ),
  on(CasesActions.clearCasesState, () => ({
    ...initialState,
  })),
  on(CasesActions.casesActionError, (state, { error }) => ({
    ...state,
    error,
    loading: false,
    loadingCompatibleWorkspaces: false,
  })),
  on(CasesActions.currWorkspaceLoaded, (state, { workspace }) => ({
    ...state,
    currWorkspace: workspace,
    error: null,
  })),
  on(CasesActions.setCurrentCaseId, (state, { caseId }) => ({
    ...state,
    currentCaseId: caseId,
  }))
);
export function reducer(state: State | undefined, action: Action) {
  return casesReducer(state, action);
}
