import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { HeaderFooterService } from "@shared/ui";
import { environment } from "@telespot/shared/environment";
import { UpdaterService } from "@telespot/shared/sw-updater";
import { Observable, Subject, Subscription } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "ts-telespot-shell",
  templateUrl: "./telespot-shell.component.html",
  styleUrls: ["./telespot-shell.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TelespotShellComponent implements OnInit, OnDestroy {
  showFooter$: Observable<boolean> = this.headerFooterService.$showFooter;
  showHeader$: Observable<boolean> = this.headerFooterService.$showHeader;
  showLogo$: Observable<boolean> = this.headerFooterService.$showLogo;

  showChatBox = false;

  get isDevBuild() {
    return !environment.production && environment.show_development_watermark;
  }
  private _snackbarRef: MatSnackBarRef<any>;
  private _snackbarSubscription: Subscription;

  private _destroy$ = new Subject<void>();
  ngOnDestroy() {
    this._destroy$.next();
  }

  constructor(
    private translate: TranslateService,
    private headerFooterService: HeaderFooterService,
    private updaterService: UpdaterService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.updaterService.notifications$
      .pipe(
        switchMap(async (payload) => {
          const translated: {
            [key: string]: string;
          } = await this.translate
            .get(
              [payload.message, payload?.actionData?.action, undefined].filter(
                (k) => !!k
              )
            )
            .toPromise();
          return {
            ...payload,
            message: translated[payload.message] ?? payload.message,
            actionData: {
              ...payload.actionData,
              action:
                translated[payload.actionData?.action] ??
                payload.actionData?.action,
            },
          };
        }),
        takeUntil(this._destroy$)
      )
      .subscribe((notification) => {
        this._snackbarRef?.dismiss();
        this._snackbarRef = this.snackbar.open(
          notification.message,
          notification.actionData?.action,
          {
            duration: notification.duration,
            panelClass: "snackbar-primary",
          }
        );
        this._snackbarSubscription?.unsubscribe();
        if (notification.actionData?.callback) {
          this._snackbarSubscription = this._snackbarRef
            .onAction()
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => {
              notification.actionData?.callback();
            });
        }
      });
  }
}
