import { Object as ParseObject } from "parse";
import { environment } from "@telespot/shared/environment";

export enum AssistantFields {
  TABLE = "Assistant",
  NAME = "name",
  DESCRIPTION = "description",
  PERSONALITYPROMPT = "personalityPrompt",
  APISPEC = "apiSpec",
  ICONURL = "iconUrl",
}

export class Assistant extends ParseObject {
  static className = "Assistant";

  constructor() {
    super(Assistant.className);
  }

  get name(): string {
    return this.get("name");
  }

  get description(): any | undefined {
    return this.get(AssistantFields.DESCRIPTION) || {};
  }

  get personalityPrompt(): string {
    return this.get(AssistantFields.PERSONALITYPROMPT || "");
  }

  set personalityPrompt(prompt: string) {
    this.set(AssistantFields.PERSONALITYPROMPT, prompt);
  }

  get iconUrl(): string {
    return this.get(AssistantFields.ICONURL || "");
  }

  get apiSpec(): any | undefined {
    return this.get(AssistantFields.APISPEC) || {};
  }

  get iconImage(): string {
    //todo: specify correct root
    return `${environment.api.url}/files/****/${this.iconUrl}/****`;
  }
}
