import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatBoxComponent } from "./components/chat-box/chat-box.component";
import { TelespotChatDataAccessModule } from "@telespot/chat/data-access";

import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TelespotAnalysisRefactorDataAccessModule } from "@telespot/analysis-refactor/data-access";
@NgModule({
  imports: [
    CommonModule,
    TelespotChatDataAccessModule,
    FormsModule,
    TelespotAnalysisRefactorDataAccessModule,
    TranslateModule,
  ],
  declarations: [ChatBoxComponent],
  exports: [ChatBoxComponent],
})
export class TelespotChatFeatureModule {}
